<template>
  <div class="branch-form">
    <v-form class="form" ref="form" @submit.prevent="validateForm">
      <v-row>
        <v-col cols="6">
          <v-text-field
              @keydown.enter="focusNext"
              outlined
              dense
              class="label-input"
              :label="$t('branch.branchName')"
              :rules="nameRules"
              v-model="branch.branch_name"
              :hint="`${server_errors.name}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>

        <v-col cols="4" md="6">
          <v-select
              @keydown.enter="focusNext"
              :label="$t('branch.province')"
              v-model="branch.province_id"
              dense
              outlined
              :items="provinces"
              item-text="name"
              :rules="nameRules"
              required
              item-value="id">
          </v-select>
        </v-col>
        <v-col cols="4" md="6">
          <v-select
              @keydown.enter="focusNext"
              :label="$t('branch.district')"
              v-model="branch.district_id"
              dense
              outlined
              :items="districts"
              :rules="nameRules"
              required
              item-text="name"
              item-value="id">
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
              @keydown.enter="focusNext"
              outlined
              v-model="branch.village"
              dense
              class="label-input"
              :label="$t('branch.village')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
              @keydown.enter="focusNext"
              outlined
              dense
              v-model="branch.description"
              :label="$t('branch.description')"
          >

          </v-textarea>
        </v-col>

        <v-col cols="8">
          <v-btn class="btn-save-change" type="submit">
            {{ $t("branch.edit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    branch: {
      default() {
        return {province_id: null};
      }
    },
  },
  data() {
    return {
      nameRules: [
        v => !!v || 'ກະລຸນາປ້ອນ...'
      ],
      btnLoading: false,
      provinces: [],
      districts: [],
      selectedProvince: {},
      selectedDistrict: {},
      filterDistricts: [],

      server_errors: {
        name: "",
        province_id: "",
        district_id: "",
        village: "",
        description: ""
      }
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },

    resetForm() {
      this.$refs.form.reset();
    },
    FetchDistrict() {
      return this.$axios.get('list/addresses').then((res) => {
        const items = res.data;
        this.provinces = items.provinces;
        this.selectedProvince = {...this.provinces[0]};
        this.filterDistricts = items.districts;
      }).catch(() => {

      });
    },

    FilterDistricts(provinceID) {
      this.districts = this.filterDistricts.filter(item => {
        if (item.province_id === provinceID) {
          return item;
        }
      });
    },


    saveChange() {
      const branch_items = {
        name: this.branch.branch_name,
        district_id: this.branch.district_id,
        village: this.branch.village,
        description: this.branch.description
      }
      this.$axios.put(`company/edit/company/branch`, branch_items, {
        params: {
          id: this.branch.id
        }
      }).then((res) => {
        if (res) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.btnLoading = false;
        this.$emit("success")
        this.$emit("close")
        this.resetForm();
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },

  watch: {
    'branch.province_id': function (provinceID) {
      this.FilterDistricts(provinceID);
    },
  },
  async created() {
    await this.FetchDistrict();
    this.FilterDistricts(this.branch.province_id);
  }
}
</script>

<style scoped lang="scss">
.branch-form {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 800px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
</style>
