<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("branch.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
          class="btn btn-color btn-border btn-border-color"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('branch.search')"
          v-model="searchItem"
          @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("branch.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listBranch.length > 0">
          <thead>
            <tr>
              <th class="text-center"></th>
              <th class="text-left">
                {{ $t("branch.branchName") }}
              </th>
              <th class="text-left">
                {{ $t("branch.companyName") }}
              </th>
              <th class="text-left">
                {{ $t("branch.phone") }}
              </th>

              <th class="text-left">
                {{ $t("branch.village") }}
              </th>
              <th class="text-left">
                {{ $t("branch.district") }}
              </th>
              <th class="text-left">
                {{ $t("branch.province") }}
              </th>
              <th>
                {{ $t("branch.description") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in listBranch" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                {{ item.branch_name }}
              </td>
              <td>
                {{ item.company_name }}
              </td>
              <td>
                {{ item.company_phone }}
              </td>

              <td>
                {{ item.village }}
              </td>
              <td>
                {{ item.district }}
              </td>
              <td>
                {{ item.province }}
              </td>
              <td>
                {{ item.description }}
              </td>

              <td></td>
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-edit"
                        @click="onEdit(item.id)"
                        >Edit</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item.id)"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <default-table-no-result v-else />
        <Pagination
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchBranch"
        >
        </Pagination>
        <ModalEdit fullscreen title="Edit Branch">
          <template v-slot="{ close }">
            <EditBranch
              :branch="branch"
              @close="close"
              @success="fetchBranch()"
            />
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{ close }">
            <DeleteBranch
              :branch_id="branch_id"
              @close="close"
              @success="fetchBranch()"
            />
          </template>
        </ModalDelete>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import DeleteBranch from "@/components/Branch/DeleteBranch";
import EditBranch from "@/components/Branch/EditBranch";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    DeleteBranch,
    EditBranch,
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: true,
      modalDeletePosition: false,
      branch: {},
      branch_id: "",
      listBranch: [],
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchBranch();
    },
    onCreate() {
      this.$router.push({ name: "branch.create" }).catch(() => {});
    },

    filterBranch(branch_id) {
      return (
        this.listBranch.filter((item) => {
          return item.id == branch_id;
        })[0] || {}
      );
    },

    onEdit(branch_id) {
      this.branch = {
        ...this.filterBranch(branch_id),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(branch_id) {
      this.branch_id = branch_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchBranch() {
      this.isLoading = true;
      this.$axios
        .get(`company/list/company/branches`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res) {
            this.isLoading = false;
            this.listBranch = res.data.companyBranches;
            this.pagination = res.data.data.pagination;
            if (!this.listBranch.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
</style>
